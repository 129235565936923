<template>
  <div>
    <div class="maintitle">回收哥位置</div>
    <el-divider></el-divider>
    <el-row :gutter="20">
      <el-col :span="4">
        <el-select v-model="crntdistrict" placeholder="请选择地区" style="width:120px;" @change="changarea">
          <el-option
            v-for="(item,index) in district"
            :key="index"
            :label="item.fullname"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-button type="primary" @click="getlist">查找</el-button>
      </el-col>
    </el-row>
    <div id="container">
      <el-amap class="amap-box" :center="center" :zoom="zoom">
        <!-- <el-amap-marker vid="component-marker" :position="componentMarker.position" :content-render="componentMarker.contentRender" ></el-amap-marker> -->
        <el-amap-marker
          v-for="(marker, index) in list"
          :position="position(marker.location)"
          :key="index"
          :title="marker.name"
        ></el-amap-marker>
      </el-amap>
    </div>
  </div>
</template>
<script>
import VueAMap from "vue-amap";
import Vue from "vue";
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: "f8797ef1e0fd8d201e7470ae64a466ad",
  plugin: [
    "AMap.PolyEditor" //编辑 折线多，边形
  ],
  // 默认高德 sdk 版本为 1.4.4
  v: "1.4.4"
});
export default {
  data() {
    return {
      list: [],
      crntdistrict: "", //当前选择县/区
      district: [], //县/区列表
      //地图相关
      zoom: 12,
      center: [118.487893, 36.686229],
      show: false,
      pas: "",
      clean: "",
      date: "",
      search: "",
      mixed: "",
      stat1: "",
      restaurants: [],
      maxlength: "",
      num: 1
    };
  },
  methods: {
    getlist: function() {
      this.$axios({
        url: this.HOST + "/jiameng/huishouge/getLocation",
        method: "post",
        data: {
          district: this.crntdistrict
        }
      }).then(res => {
        this.list = res.data;
      });
    },
    changarea(){
      this.$axios({
        method:'post',
        url:this.HOST + '/jiameng/index/getLocation',
        data:{
          district:this.crntdistrict
        }
      }).then(res=>{
        this.center = [
          res.data.lng,
          res.data.lat
        ]
      });
    },
    getAreaList() {
      let url = this.HOST + "/jiameng/index/getAreaList";
      this.$axios({
        method: "post",
        url: url,
        data: {}
      }).then(res => {
        if (res.data.status == 1) {
          this.district = res.data.list;
        } else {
          return [];
        }
      });
    },
    // handlemyMapFn() {
    //   VueAMap.initAMapApiLoader({
    //     key: "f8797ef1e0fd8d201e7470ae64a466ad",
    //     plugin: [
    //       "AMap.PolyEditor" //编辑 折线多，边形
    //     ],
    //     // 默认高德 sdk 版本为 1.4.4
    //     v: "1.4.4"
    //   });
    // },
    position(str) {
      if (str) {
        let p = JSON.parse(str);
        return [parseFloat(p.lng), parseFloat(p.lat)];
      } else {
        return [0, 0];
      }
    },
  },
  computed: {},
  created: function() {
    this.getAreaList();
  }
};
</script>
<style>
#container {
  width: 100%;
  height: 600px;
}
</style>